import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/SEO";

function TreTipsTilAtOegeMuskelmassen() {
  return (
    <>
      <SEO
        title="3 tips til at øge muskelmassen"
        description="Arbejder du for at øge muskelmassen men mangler at se markante resultater? Læs artiklen og få nogle råd og tips til, hvad der kan hjælpe med resultater til din træning."
      />
      <article className="flex flex-col items-center mx-auto max-w-4xl my-12">
        <h1 className="font-bold text-4xl mb-4">
          3 tips til at øge muskelmassen
        </h1>
        <p className="mb-4 text-2xl leading-10">
          Føler du, at du arbejder hårdt i fitnesscenteret og stadig ikke ser
          nogle resultater? Det kan være enormt demotiverende, at besøge
          fitnesscenteret flere gange om ugen - og stadig ikke føle at man ser
          nogen ændringer, hvad end du ønsker større arme, tydelig sixpack eller
          bare en generel flottere fysik.
        </p>
        <p className="mb-4 text-2xl leading-10">
          Selvom træningen er den vigtigste faktor for den flottere fysik, er
          der andre regler, der kan have en enorm effekt på det endelige
          resultat. Det vil vi her dele med jer, i dette opslag.
        </p>
        <h2 className="font-bold text-3xl my-4">Kostplanen</h2>
        <p className="mb-4 text-2xl leading-10">
          Du har formentligt hørt og set det før. For kosten er alfa-omega, for
          at se det resultat du ønsker, når det gælder det endelige resultat. Og
          faktisk, er viden omkring kosten ikke det store udfordring.
          Udfordringen er, at holde det ved lige og rent faktisk følge
          kostplanen.
        </p>
        <p className="mb-4 text-2xl leading-10">
          Protein er kroppens byggesten. Det er vigtigt at du indtager nok
          protein - og altså <b>gode proteiner</b>. Selvom det ville være
          dejligt nemt, at få sit protein via flæskesvær, som altså indeholder
          +60 gram protein pr. 100 gram, er dette et no-go. Hold dig til de gode
          proteiner, som vi typisk finder i kylling, fisk, kød, linser, bønner,
          tofu, kikærter, sojabønner og æg. Altså er der masser af muligheder om
          du er vegetar eller ej.
          <br />
          Udover disse proteinkilder, kan det være en god ide, at indtage
          protein i form af kosttilskud. Du kan finde et stort udvalg af
          proteinpulver{" "}
          <Link className="underline font-bold" to="/proteintilskud">
            her
          </Link>{" "}
          på siden.
          <br />
          Du kan vælge at måle din mad i kalorier. Men dette kan være trættende
          på sigt og det kan lade sig gøre på andre måder. Vil du gerne undgå
          dette, er en anden mulighed, at stille dig foran spejlet d. 1 hvert
          måned, og evalurere, hvad du gerne vil forbedre. Vil du gerne have
          større arme? Så fokuser primært på armene den næste måneds-tid. Du
          træner selvfølgelig også resten af kroppen, men med fokuset lagt på
          armene. Og hvis du gerne vil vokse, så sørg for at få nok mad - uden
          at få for meget. Lær din krop at kende. Vil du gerne tabe dig, så skru
          ned for kalorierne, og fortsæt med at gøre op med dig selv, måned
          efter måned. Synes du ikke armene er vokset efter måneden er gået, er
          der intet galt med at tage en måned eller flere efterfølgende.
        </p>
        <h2 className="font-bold text-3xl my-4">Hvile</h2>
        <p className="mb-4 text-2xl leading-10">
          Simpelt som det lyder. Og alligevel er dette en af de største
          udfordringer, blandt mange der træner. Ønsker du at dine arme skal
          vokse, ville det jo være dejligt let, at kunne træne arme alle ugens
          dage, for så at se markante resultater efter en måneds-tid. Sådan
          fungerer det desværre ikke.
          <br />
          Når man træner og løfter tungt, river vi vores muskler i stykker.
          Herfra, gælder det om at restituere og lade musklerne samle sig igen -
          nu i stærkere format, så dette ikke sker igen. Det er netop her, vi
          skal fylde vores muskler med de rigtige næringstoffer via kosten - og
          hvile, for at de kan samle sig igen. Derfor vil det ikke fungere, hvis
          vi træner samme muskelgruppe dag ud og dag ind, i håb om at det ville
          skabe resultaterne hurtigere. <br />
          Det anbefales, at vente minimum 48 timer, før du træner samme
          muskelgruppe igen. Altså 2 døgn og gerne mere. Det er af denne årsag
          også vigtigt, at man ved hvilke øvelser, der rammer de forskellige
          muskler. Hvis du træner triceps den ene dag, vil det derfor ikke
          anbefales at træne bryst dagen efter, da du vil benytte tricepsen
          igen, ved for eksempel bænkpres og andre øvelser hvor man "skubber".{" "}
          <br />
          Sidst men ikke mindst, husk at få nok søvn. Det kan have en stor
          effekt, om du får 5 timer - som er for lidt, eller 8 timers nattesøvn
          som anbefales.
        </p>
        <h2 className="font-bold text-3xl my-4">Cardio</h2>
        <p className="mb-4 text-2xl leading-10">
          Er et emne der kan diskuteres en del. Er træningen nok i sig selv? Ja,
          det er den vel egentligt. Alligevel, stemmer vi for at du har et par
          dage om ugen, eller nogle minutter før eller efter træningen, som er
          dedikeret til cardio, hvad end det er løb, en tur på cyklen, svømning,
          eller hvad der passer dig bedst. Med cardio, holder vi formen ved
          lige, hvilket der kun har en positiv effekt. En effekt, vi ikke får
          ved <i>kun</i> at stærketræne. Derudover er det godt til din
          forbrænding, og altså holde din markeringer skarpere. Så altså, vi
          anbefaler at du får din cardio i løbet af ugen, uden at det skal være
          dit primære fokus, hvis du ønsker at få større muskler.
        </p>
        <p className="mb-4 text-2xl leading-10">
          Det kunne være dejligt let, hvis der bare var én metode, man kunne
          forholde sig til, når det gælder styrketræning. Men eftersom ingen
          krop er ens og lysten til træningen kan være forskelligt fra person
          til person, gælder det altså om at finde måden <i>du</i> trives bedst
          med. Alligevel, er træningen, kosten, hvile og konditionen de
          vigtigste punkter, når det gælder bodybuilding, og denne artikel er
          skrevet ud fra vores holdninger omkring styrketræning, og hvad der er
          vigtigt at forholde sig til.
        </p>
      </article>
    </>
  );
}

export default TreTipsTilAtOegeMuskelmassen;
